.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:white;
  color: black;
  padding: 1rem;
  border: 1px solid #ccc;
  max-height: 75vh;
}
.toolbar-class {
  border: 1px solid #ccc;
}
